<template>
  <div class="batch-scan">
    <div class="batch-scan-box" >
      <div v-if="stepIndex > -1">
        <Alert show-icon style="margin-top: 40px;">
          提示
          <template slot="desc">
            <h3 style="color: #888888">上传后还需等待识别，一个班(45人)批量识别预计需要5～8分钟。</h3>
            <h4 style="color: #888888">上传成功后，可继续批量扫描。</h4>
          </template>
        </Alert>
        <Card dis-hover >
          <Steps :current="stepIndex">
            <Step :title="item.name" :content="item.desc" v-for="(item,index) in stepList" :key="index"></Step>
          </Steps>
        </Card>
      </div>
      <div v-else style="height: 232px;"></div>
      <Card dis-hover style="margin-top: 10px;">
        <div class="batch-scan-item">
          <div class="batch-scan-item-left">
            <div v-if="loading">
              <div class="loadingio-eclipse">
                <div class="loadingio-text">
                  <span v-text="stepList[stepIndex] ? stepList[stepIndex].name : '处理中'"></span>
                </div>
                <div class="ldio-rpinwye8j0b">
                  <div></div>
                </div>
              </div>
            </div>
            <div class="scan-img" v-else>
              <img src="../../../assets/images/home/scan.png"/>
            </div>
          </div>
          <div class="batch-scan-item-right">
            <div class="form-item">
              <Form :model="ajaxForm" :label-width="80">
                <FormItem label="选择设备">
                  <div class="jss-flex">
                    <div class="jss-flex-big">
                      <Select size="large" v-model="ajaxForm.deviceId" @on-change="setDefaultDevice">
                        <Option :value="item.deviceId" :label="item.nickName"
                                v-for="(item,index) in deviceList" :key="index"
                                :disabled="item.status == 2">
                          <span v-text="item.nickName"></span>
                          <span style="float:right;color: #3C7CFC" v-if="item.status == 1">在线</span>
                          <span style="float:right;color: #999999" v-else>离线</span>
                        </Option>
                      </Select>
                    </div>
                    <div class="jss-flex-min">
                      <Button size="large" :loading="refreshLoading" icon="md-refresh"
                              @click="refreshDevice">刷新设备
                      </Button>
                    </div>
                  </div>
                </FormItem>
                <FormItem :label-width="40">
                  <div style="margin-top: 35px;display: flex">
                    <Button type="primary" size="large" style="width: 70%" :loading="loading" long @click="submit">
                      <span v-if="!loading">开始扫描</span>
                      <span v-else>扫描中...</span>
                    </Button>
                    <Button v-if="loading" size="large" type="warning" style="margin-left: 20px;width: 100px;"
                            @click="cancelCommand">取消
                    </Button>
                  </div>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
      </Card>
    </div>
    <Modal v-model="modals" width="360">
      <p slot="header" :style="{color: modalType == 1 ? '#19be6b' : '#ff9900',textAlign: 'center'}">
        <Icon type="ios-information-circle"></Icon>
        <span v-text="modalType == 1 ? '扫描成功' : '扫描失败'"></span>
      </p>
      <div style="text-align:center;min-height: 40px;">
        <h3 v-text="msg"></h3>
      </div>
      <div slot="footer">
        <Button :type="modalType == 1 ? 'success' : 'warning'" size="large" long @click="modals = false">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "batch-scan",
  components: {},
  data() {
    return {
      loading: false,
      modals: false,
      refreshLoading: false, // 刷新设备状态
      modalType: 1, // 1:成功；2： 失败
      msg: '',
      interval: '',
      ajaxForm: {
        deviceId: '',
        type: 0
      },
      stepIndex: -1,
      stepList: [
        {
          id: 1,
          name: '扫描中',
          desc: '设备正在扫描'
        },
        {
          id: 2,
          name: '打包中',
          desc: '文件打包'
        },
        {
          id: 3,
          name: '上传中',
          desc: '文件上传'
        },{
          id: 4,
          name: '上传完成',
          desc: '完成'
        }
      ],
      deviceList: []
    }
  },
  created() {
  },
  mounted() {
    this.queryUserDevice()
  },
  destroyed() {
    this.cancelCommand()
  },
  methods: {
    // 刷新设备
    refreshDevice() {
      this.refreshLoading = true
      this.queryUserDevice()
    },
    // 设置默认设备
    setDefaultDevice(item = null) {
      if (item) {
        sessionStorage.setItem('deviceId', item)
      } else {
        let deviceId = this.ajaxForm.deviceId || null
        if (deviceId) {
          sessionStorage.setItem('deviceId', deviceId)
        }
      }
    },
    // 获取默认设备
    getDefaultDevice(list = []) {
      let deviceId = sessionStorage.getItem('deviceId') || false
      if (deviceId) {
        list.forEach((item) => {
          if (item.deviceId == deviceId) {
            if (item.status == 1) {
              deviceId = item.deviceId
            } else {
              deviceId = ''
            }
            this.ajaxForm.deviceId = deviceId
          }
        })
      } else {
        if (list.length > 0) {
          let item = list[0].deviceId
          if (item.status == 1) {
            this.ajaxForm.deviceId = list[0].deviceId
            this.setDefaultDevice()
          }
        }
      }
    },
    queryUserDevice() {
      this.$http.queryUserDevice().then((res) => {
        if (res.code == 200) {
          this.refreshLoading = false
          let list = res.data || []
          this.deviceList = list
          this.getDefaultDevice(list)
        }
      })
    },
    // 取消扫描
    cancelCommand() {
      let deviceId = this.ajaxForm.deviceId || ''
      if (deviceId) {
        this.cancel()
        this.$http.deviceCommand(4, deviceId)
      }
    },
    // 提交扫描请求
    submit() {
      let that = this
      this.loading = true
      this.stepIndex = 0
      let deviceId = this.ajaxForm.deviceId
      this.$http.deviceCommand(1, deviceId).then((res) => {
        if (res.code == 200) {
          that.interval = setInterval(() => {
            that.getScanStatue(deviceId)
          }, 1000)
        } else {
          that.showModel(2, res.msg || '设备扫描失败')
        }
      })
    },
    // 显示弹窗
    showModel(type, msg) {
      this.modalType = type
      this.msg = msg
      this.modals = true
      this.cancel()
    },
    // 取消扫描
    cancel() {
      this.loading = false
      this.stepIndex = -1
      clearInterval(this.interval)
    },
    getScanStatue(deviceId) {
      try{
        this.$http.queryDeviceStatus(deviceId).then((res) => {
          if (res.code == 200) {
            let data = res.data
            switch (data.type) {
              case 0:
              case 4:
                // 完成
                this.showModel(1, '扫描完成，可继续扫描。')
                break
              case 5:
                // 扫描错误
                this.showModel(2, '设备无纸或卡纸，请重新放置纸张。')
                break
              default:
                this.stepIndex = data.type - 1
                break
            }
          }
        })
      }catch (e){
        this.showModel(1, '扫描完成')
      }
    }
  }
}
</script>

<style scoped lang="less">
.batch-scan {
  display: flex;
  justify-content: center;

  .batch-scan-box {
    width: 700px;

    .batch-scan-item {
      display: flex;
      border: 1px solid #9ee0fd;
      border-radius: 6px;
      padding: 20px;
    }

    .batch-scan-item-left {
      width: 30%;

      .scan-img {
        height: 140px;
      }
    }

    .batch-scan-item-right {
      width: 70%;
    }
  }
}

@keyframes ldio-rpinwye8j0b {
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.ldio-rpinwye8j0b div {
  position: absolute;
  animation: ldio-rpinwye8j0b 1.5s linear infinite;
  width: 100px;
  height: 100px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #4086fc;
  transform-origin: 50px 52px;
}

.loadingio-eclipse {
  width: 150px;
  height: 150px;
  display: inline-block;
  overflow: hidden;
  position: relative;

  .loadingio-text {
    position: absolute;
    top: 40%;
    left: 35%;
    font-weight: bolder;
  }
}

.ldio-rpinwye8j0b {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-rpinwye8j0b div {
  box-sizing: content-box;
}

.jss-flex {
  display: flex;

  .jss-flex-big {
    flex: 2;
  }

  .jss-flex-min {
    margin-left: 5px;
  }
}
</style>
